<template>
    <validation-provider
        v-slot="{ failedRules }"
        :rules="rules"
        name="pr_content"
        :bails="false"
        :skip-if-empty="false"
        immediate
    >
        <input :value="content" name="pr_content" type="hidden" />

        <a-alert
            v-if="rules.includes('wordCountBetween')"
            :type="
                contentText === ''
                    ? 'info'
                    : failedRules.wordCountBetween
                      ? 'error'
                      : 'success'
            "
        >
            {{ wordCountText() }}
        </a-alert>

        <a-alert
            v-if="containsLinks"
            :type="
                contentText === ''
                    ? 'info'
                    : allLinksReachable
                      ? 'success'
                      : 'warning'
            "
        >
            <span v-if="allLinksReachable">
                All links have been validated and are accessible.
            </span>

            <span v-else>
                One or more of the links provided could not be reached. Please
                ensure that all links provided are accessible.
            </span>
        </a-alert>

        <a-alert
            v-if="rules.includes('linkCount')"
            :type="
                contentText === ''
                    ? 'info'
                    : failedRules.linkCount
                      ? 'error'
                      : 'success'
            "
        >
            Content should include a valid link to your article or your website.
            Links should be valid URLs including protocol (http / https). Please
            note 'mailto:email@example.com' links are not valid.
        </a-alert>

        <a-alert
            v-if="rules.includes('nonFirstPerson')"
            :type="
                contentText === ''
                    ? 'info'
                    : failedRules.nonFirstPerson
                      ? 'error'
                      : 'success'
            "
        >
            Content should not use first person language (I, Me, My, Mine, Our,
            Ours, We, Us, You, Your and Yours).
        </a-alert>

        <a-alert
            v-if="rules.includes('matchingQuotes')"
            :type="
                contentText === ''
                    ? 'info'
                    : failedRules.matchingQuotes
                      ? 'warning'
                      : 'success'
            "
        >
            Content should have consistent quote pairs.
        </a-alert>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { stripHtml, wordCount } from '@/utils/helpers';

import { ValidateLinksReachability } from '@/mixins';

import { AAlert } from '../AAlert';

const ContentEditorValidationsProps = Vue.extend({
    props: {
        content: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: String,
            default() {
                return '';
            }
        },
        linksReachability: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: { AAlert }
})
export default class ContentEditorValidations extends mixins(
    ContentEditorValidationsProps,
    ValidateLinksReachability
) {
    @Watch('content')
    onContextTextChange(value: string) {
        this.validateLinksReachability(value);
    }

    get contentText() {
        return stripHtml(this.content || '');
    }

    get words() {
        return wordCount(this.content || '');
    }

    wordCountText() {
        if (this.rules.includes('wordCountBetween')) {
            const [min, max] = this.rules
                .split('|')
                .filter(rule => rule.includes('wordCountBetween'))[0]
                .split(':')[1]
                .split(',');

            return `Content should be between ${min} and ${max} words. Current: ${this.words}`;
        }
    }
}
</script>
