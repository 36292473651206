import Quill from 'quill';

import VideoPlaceholderSVG from '@/assets/img/video-placeholder.svg';

const EmbedBlot = Quill.import('blots/embed');

class VideoPlaceholder extends EmbedBlot {
    static blotName = 'videoPlaceholder';

    static tagName = 'img';

    static create() {
        const node: HTMLElement = super.create();

        node.setAttribute('src', `${VideoPlaceholderSVG}`);
        node.setAttribute('alt', '{video_pr:link}');
        node.setAttribute(
            'title',
            'Video will appear at this section after distribution'
        );

        return node;
    }
}

export default VideoPlaceholder;
