var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-provider',{attrs:{"rules":_vm.rules,"name":"pr_content","bails":false,"skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ failedRules }){return [_c('input',{attrs:{"name":"pr_content","type":"hidden"},domProps:{"value":_vm.content}}),(_vm.rules.includes('wordCountBetween'))?_c('a-alert',{attrs:{"type":_vm.contentText === ''
                ? 'info'
                : failedRules.wordCountBetween
                  ? 'error'
                  : 'success'}},[_vm._v(" "+_vm._s(_vm.wordCountText())+" ")]):_vm._e(),(_vm.containsLinks)?_c('a-alert',{attrs:{"type":_vm.contentText === ''
                ? 'info'
                : _vm.allLinksReachable
                  ? 'success'
                  : 'warning'}},[(_vm.allLinksReachable)?_c('span',[_vm._v(" All links have been validated and are accessible. ")]):_c('span',[_vm._v(" One or more of the links provided could not be reached. Please ensure that all links provided are accessible. ")])]):_vm._e(),(_vm.rules.includes('linkCount'))?_c('a-alert',{attrs:{"type":_vm.contentText === ''
                ? 'info'
                : failedRules.linkCount
                  ? 'error'
                  : 'success'}},[_vm._v(" Content should include a valid link to your article or your website. Links should be valid URLs including protocol (http / https). Please note 'mailto:email@example.com' links are not valid. ")]):_vm._e(),(_vm.rules.includes('nonFirstPerson'))?_c('a-alert',{attrs:{"type":_vm.contentText === ''
                ? 'info'
                : failedRules.nonFirstPerson
                  ? 'error'
                  : 'success'}},[_vm._v(" Content should not use first person language (I, Me, My, Mine, Our, Ours, We, Us, You, Your and Yours). ")]):_vm._e(),(_vm.rules.includes('matchingQuotes'))?_c('a-alert',{attrs:{"type":_vm.contentText === ''
                ? 'info'
                : failedRules.matchingQuotes
                  ? 'warning'
                  : 'success'}},[_vm._v(" Content should have consistent quote pairs. ")]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }