<template>
    <div
        v-show="isVisible"
        class="custom-quill-link elevation-2"
        :style="styles"
    >
        <a-form ref="form">
            <a-text-input
                ref="input"
                :key="'content-link-' + uid"
                v-model="link"
                rules="url"
                label="URL to link"
                clearable
                dense
                placeholder="http(s)://..."
                @keypress.enter.prevent="saveLink"
                @keydown.esc="esc"
            >
                <template #append-outer>
                    <v-btn icon :disabled="!canLink" @click="saveLink">
                        <v-icon>check</v-icon>
                    </v-btn>
                    <v-btn icon :disabled="!canUnlink" @click="removeLink">
                        <v-icon>link-slash</v-icon>
                    </v-btn>
                </template>
            </a-text-input>
        </a-form>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { BoundsStatic, RangeStatic } from 'quill';
import Delta from 'quill-delta';

import uuid from '@/utils/helpers/uuid';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { StyleValue } from 'vue/types/jsx';
import { Watch } from '@/utils/decorators';

const ContentEditorLinkInputProps = Vue.extend({
    name: 'ContentEditorLinkInput',
    props: {
        linkRange: {
            type: Object as PropType<RangeStatic | null>,
            default(): RangeStatic | null {
                return null;
            }
        },
        linkBounds: {
            type: Object as PropType<BoundsStatic | null>,
            default(): BoundsStatic | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class ContentEditorLinkInput extends ContentEditorLinkInputProps {
    $refs!: {
        form: InstanceType<typeof AForm>;
        input: InstanceType<typeof ATextInput>;
    };

    linkEditorWidth = 340;

    linkEditorHeight = 92;

    link = '';
    hadLink = false;

    text = '';

    isVisible = false;

    replace = false;

    @Watch('isVisible')
    onShowInputLinkChange(isVisible = false) {
        if (isVisible) {
            setTimeout(() => {
                this.$refs.input.$el.querySelector('input')?.click();
            }, 50);
        }
    }

    get styles(): StyleValue {
        if (this.linkBounds) {
            const top =
                this.linkBounds.top < this.linkEditorHeight
                    ? this.linkBounds.top + this.linkBounds.height
                    : this.linkBounds.top - this.linkEditorHeight;

            let left = 0;

            if (!this.$vuetify.breakpoint.mobile) {
                left =
                    this.linkBounds.left < this.linkEditorWidth
                        ? this.linkBounds.left
                        : this.linkBounds.left -
                          this.linkEditorWidth +
                          this.linkBounds.width;
            }

            return {
                position: 'absolute',
                top: `${top}px`,
                left: `${left}px`
            };
        }

        return {};
    }

    get uid() {
        return uuid();
    }

    get canLink() {
        return Boolean(this.link);
    }

    get canUnlink() {
        return Boolean(this.link || this.hadLink);
    }

    newLink(selectedText: string, selectedLink: string) {
        this.replace = true;

        if (selectedText) {
            this.text = selectedText;
        }

        if (selectedLink) {
            this.link = selectedLink;
        }

        this.hadLink = Boolean(selectedLink);

        this.setVisible();
    }

    show(selectedText = '', selectedLink = '') {
        this.newLink(selectedText, selectedLink);

        const links = this.getContentLinks();

        links.forEach(link => {
            this.disableLinkPreviewFromShowing(link as HTMLElement);

            link.addEventListener(
                'click',
                this.handleLinkClickWhenLinkEditorIsOpen
            );
        });
    }

    hide() {
        this.close();

        const links = this.getContentLinks();

        links.forEach(link => {
            this.enableLinkPreviewToShow(link as HTMLElement);

            link.removeEventListener(
                'click',
                this.handleLinkClickWhenLinkEditorIsOpen
            );
        });
    }

    close() {
        Object.assign(this.$data, this.$options.data);

        this.link = '';

        this.$refs.form.reset();

        this.setVisible(false);
    }

    getContentLinks() {
        return document.querySelectorAll(
            '.ql-container.ql-bubble > .ql-editor > p  a:not([data-tle-favicon="true"])'
        );
    }

    disableLinkPreviewFromShowing(linkElement: HTMLElement) {
        linkElement.style.setProperty('--qlLinkPreviewVisibility', 'hidden');
    }

    enableLinkPreviewToShow(linkElement: HTMLElement) {
        linkElement.style.setProperty('--qlLinkPreviewVisibility', 'visible');
    }

    handleLinkClickWhenLinkEditorIsOpen(event: Event) {
        this.disableLinkPreviewFromShowing(event.target as HTMLElement);
    }

    saveLink() {
        this.$refs.form.validate().then(valid => {
            if (!valid) {
                return;
            }

            if (this.linkRange) {
                const delta = new Delta()
                    .retain(this.linkRange.index)
                    .delete(this.replace ? this.linkRange.length : 0);

                if (this.link) {
                    delta.insert(this.text.trim(), { link: this.link });
                } else {
                    delta.insert(this.text.trim());
                }

                this.$emit('link', delta);

                this.setVisible(false);
            }
        });
    }

    removeLink() {
        this.link = '';

        this.saveLink();
    }

    setVisible(isVisible = true) {
        this.isVisible = isVisible;
    }

    esc() {
        this.setVisible(false);

        this.$emit('escape');
    }
}
</script>

<style lang="scss" scoped>
.custom-quill-link {
    background-color: $blueish;
    padding: 8px;
    padding-bottom: 0;
    border-radius: 5px;
    min-width: 380px !important;
}
</style>
